import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { TipoCompraRestcService } from '../../client/tipo-compra/tipo-compra-restc.service';

@Injectable({
  providedIn: 'root'
})
export class TipoCompraPsService extends BaseProcess<TipoCompraRestcService>{

  constructor(httpClient: HttpClient, crest: TipoCompraRestcService) {
    super(httpClient,crest);
  }
}