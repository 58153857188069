import { Component, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { CompraVw } from 'src/app/api/compras/compra';
import { CompraPsService } from 'src/app/procs/compra/compra-ps.service';

@Component({
  selector: 'axks-compras-pendientes',
  templateUrl: './compras-pendientes.component.html',
  styleUrls: ['./compras-pendientes.component.scss']
})
export class ComprasPendientesComponent extends BaseView implements OnInit {

  myLoadContent: boolean;
  showContent: boolean;
  items: CompraVw[];

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProv: SessionClientProvider,
    private comprasRestc: CompraPsService
  ) {
    super(screenModeService, deviceService, messageService, sessionProv);

    this.myLoadContent = false;
  }

  ngOnInit(): void {
    this.getComprasPendientes();
    if(!this.items){
      this.items = [];
    }
  }

  initContent() {
    if (this.items.length > 0) {
      this.showContent = true;
    }
    else {
      this.showContent = false;
    }
  }

  getComprasPendientes() {
    this.comprasRestc.itemsForDashboard().subscribe(
      (data) => {
        this.items = data;
        this.initContent();
        this.myLoadContent = true;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo obtener las compras pendientes");
        this.myLoadContent = true;
      }
    );
  }

  selectItem(event) {
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set('r', 'true');
    urlParams.set('sid', event.value.id);
    let urlRedirect = window.location.origin + "/compras" + "?" + "e=" + urlParams.get('e') + "&a=" + urlParams.get('a') + (urlParams.get('s') ? "&s=" + urlParams.get('s') : "&dev=" + urlParams.get('dev')) + "&r=" + urlParams.get('r') + "&sid=" + urlParams.get('sid');
    window.parent.postMessage({ for: "redirect", url: urlRedirect }, "*");
  }

}
