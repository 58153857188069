<!-- <ng-container *ngIf="loadContent"> -->
<!-- 
    {{historico |json}} -->
<p-tabView>
    <p-tabPanel rightIcon="pi pi-clock" header="Seguimiento de la solicitud"
        [style]="{'padding-top':'1vh', 'padding-bottom': '1vh','display':'grid', 'height':'50vh', 'overflow-x':'hidden', 'overflow-y':'auto'}">
        <p-card [style]="{ 'min-height': '48vh', width: '100%' }">
            <p-timeline [value]="historico" align="right">
                <ng-template pTemplate="content" let-event>
                    <span class="p-text-primary event-button" (click)="onSelSeguimiento($event, event)">
                        {{ getEstatusLabel(event.idEstatus) }}</span>
                    <small class="p-text-secondary" style="color:#34A835"
                        *ngIf="event.actual == true && validaAccion(event.idEstatus)">
                        <br>Acción Requerida
                    </small>
                </ng-template>

                <ng-template pTemplate="opposite" let-event>
                    <small class="p-text-secondary">{{event.fecEstatus |date: "dd 'de' MMMM 'de' yyyy hh:mm a":
                        'es-MX'}}</small>
                </ng-template>
            </p-timeline>
        </p-card>
    </p-tabPanel>
</p-tabView>

<!-- </ng-container> -->
<p-dialog [(visible)]="displayDialog" *ngIf="displayDialog" [modal]="true" [draggable]="false" position="right" [style]="dialogStyle()">

    <ng-template pTemplate="header">
        {{getEstatusLabel(selSeguimiento.idEstatus)}}
    </ng-template>
    <p-panel [toggleable]="true">
        <ng-template pTemplate="header">
            Detalle {{getEstatusLabel(selSeguimiento.idEstatus) | lowercase}}
        </ng-template>

        <axks-det-seguimiento [seguimiento]="selSeguimiento"></axks-det-seguimiento>
    </p-panel>
    <p-panel [toggleable]="true"
        *ngIf="selSeguimiento.idEstatus != ESTATUS_SOLICITUD.FINALIZADA &&
            selSeguimiento.idEstatus != ESTATUS_SOLICITUD.CANCELADA && 
            selSeguimiento.idEstatus != ESTATUS_SOLICITUD.RECHAZADA && 
            selSeguimiento.actual">
        <ng-template pTemplate="header">
            Acciones a realizar
        </ng-template>

        <!-- Aplica para usuario finanzas -->
        <axks-accion-seguimiento *ngIf="modoGestion"
            [seguimiento]="selSeguimiento" (onFinAccion)="onFinAccion($event)"></axks-accion-seguimiento>
        <!-- Finaliza el flujo -->

        <!-- Aplica para empleado -->
        <axks-accion-seguimiento-empleado *ngIf="!modoGestion"
            [seguimiento]="selSeguimiento" (onFinAccion)="onFinAccion($event)"></axks-accion-seguimiento-empleado>
        <!-- Finaliza el flujo -->

    </p-panel>
</p-dialog>