<div class="p-grid">

    <div class="p-col-12">
        <div class="det-field-lbl">Fecha estatus</div>
        <div class="det-field-val">
            {{seguimiento.fecEstatus | date:"dd 'de' MMMM 'de' yyyy, hh:mm a" : "es-MX"}}
        </div>
    </div>
    <div class="p-col-12" *ngIf="seguimiento.observaciones && seguimiento.observaciones.trim() != ''">
        <div class="det-field-lbl">Observaciones:</div>
        <div class="det-field-val">
            {{seguimiento.observaciones}}
        </div>
    </div>
</div>