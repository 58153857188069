import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Estatus } from '../../../api/compras/estatus-compra';

@Component({
  selector: 'axks-estatus-compras-form',
  templateUrl: './estatus-compras-form.component.html',
  styleUrls: ['./estatus-compras-form.component.scss']
})
export class EstatusComprasFormComponent extends BaseView implements OnInit {

  @Input() item: Estatus = {};


  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {} as Estatus;
    }
  }

}
