import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'axks-tipo-operacion',
  templateUrl: './tipo-operacion.component.html',
  styleUrls: ['./tipo-operacion.component.scss']
})
export class TipoOperacionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
