import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { TipoOperacionRestcService } from 'src/app/client/tipo-operacion/tipo-operacion-restc.service';

@Injectable({
    providedIn: 'root'
})
export class TipoOperacionPsService extends BaseProcess<TipoOperacionRestcService> {

    constructor(httpClient: HttpClient, crest: TipoOperacionRestcService) {
        super(httpClient, crest);
    }
}
