<div class="p-grid frm-part" >
    
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12">
        <span class="p-float-label">
            <input id="float-input" type="text" pInputText [(ngModel)]="item.tipoOperacion">
            <label for="float-input">Tipo operación</label>
        </span>
    </div>

    
</div>
