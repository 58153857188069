import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable } from 'rxjs';
import { CompraVw } from 'src/app/api/compras/compra';
import { CompraRestcService } from 'src/app/client/compra/compra-restc.service';

@Injectable({
  providedIn: 'root'
})
export class CompraPsService extends BaseProcess<CompraRestcService>{

  client: CompraRestcService;

  constructor(httpClient: HttpClient, crest: CompraRestcService) {
    super(httpClient, crest)
    
    this.client = crest;
  }

  itemsForDashboard(): Observable<CompraVw[]> {
    return this.client.itemsForDashboard();
  }
}
