import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { AccionSeguimientoEnum, EstatusSolicitud } from 'src/app/api/enums/solicitud.enum';
import { SeguimientoCompra, TipoOperacion } from 'src/app/api/compras/seguimiento-compra';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { SeguimientoCompraPsService } from 'src/app/procs/compra/seguimiento-compra-ps.service';
import { ProfileC } from '@axks/components/lib/api/views/profile';

@Component({
    selector: 'axks-accion-seguimiento',
    templateUrl: './accion-seguimiento.component.html',
    styleUrls: ['./accion-seguimiento.component.scss']
})
export class AccionSeguimientoComponent extends BaseView implements OnInit {

    ESTATUS = EstatusSolicitud;
    ACCION = AccionSeguimientoEnum;

    @Input() seguimiento: SeguimientoCompra;
    observaciones: string;
    tipoOperacion: TipoOperacion;
    @Output() onFinAccion = new EventEmitter();

    constructor(
        protected screenModeService: OperationScreenModeService,
        protected deviceService: DeviceDetectorService,
        protected messageService: MessageService,
        protected sessionProvier: SessionClientProvider,
        private segCompPs: SeguimientoCompraPsService
    ) {
        super(screenModeService, deviceService, messageService, sessionProvier);
    }

    ngOnInit(): void {
        if (!this.seguimiento) {
            this.seguimiento = {};
        }

        if (this.seguimiento.idTipoOperacion) {
            this.tipoOperacion = { id: this.seguimiento.idTipoOperacion } as TipoOperacion;
        }
    }

    showStatus(estatusSolicitud: EstatusSolicitud) {
        return (this.seguimiento.idEstatus == estatusSolicitud
            && this.seguimiento.actual) ? true : false;
    }

    onAccion(event, accion: AccionSeguimientoEnum) {

        if (!this.observaciones) {
            this.warningMessage("Seguimiento a la compra", "Favor de proporcionar un comentario en el campo observaciones.");
        } else {
            this.showProgress = true;
            let profile = this.getProfile() as ProfileC;
            let seg = {
                idCompra: this.seguimiento.idCompra,
                observaciones: this.observaciones,
                idEmpleado: profile.idEmpleado
            } as SeguimientoCompra;
            if (accion == AccionSeguimientoEnum.FINALIZAR) {
                if (!this.tipoOperacion) {
                    this.showProgress = false;
                    this.warningMessage("Seguimiento a la compra", "Favor de seleccionar una operación del campo 'Tipo de operación'.");
                    return;
                }
                seg.idTipoOperacion = this.tipoOperacion.id;
            }

            this.segCompPs.registraSeguimiento(seg, accion).subscribe(
                (data) => {
                    this.infoMessage("Seguimiento a la compra", "Se almacenó correctamente la acción.");
                    this.onFinAccion.emit();
                    this.showProgress = false;
                },
                (error) => {
                    this.showProgress = false;
                    this.errorMessage("Error", "No se pudo avanzar la solicitud");
                }
            );
        }
    }

    muestraFinalizacion(idEstatus: number) {
        switch (idEstatus) {
            case EstatusSolicitud.AUTORIZADA:
            case EstatusSolicitud.EN_ESPERA:
                return true;
        }

        return false;
    }

    onChangeTipoOperacion(event) {
        this.tipoOperacion = event;
    }


}
