import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Compra, Empleadocat } from 'src/app/api/compras/compra';
import { Estatus } from 'src/app/api/compras/estatus-compra';
import { EmpleadoNombre } from 'src/app/api/empleado/empleado';

@Component({
    selector: 'axks-compras-form',
    templateUrl: './compras-form.component.html',
    styleUrls: ['./compras-form.component.scss']
})
export class ComprasFormComponent extends BaseView implements OnInit {

    @Input() item: Compra = {};
    @Input() estatus: Estatus[];
    @Input() modoGestion: boolean;
    @Input() empleados: EmpleadoNombre[];

    modoOperacion = OperationMode;
    catEmpleados: Empleadocat[] = [];

    constructor(protected screenModeService: OperationScreenModeService,
        protected deviceService: DeviceDetectorService,
        protected messageService: MessageService,
        protected sessionProvier: SessionClientProvider,
        ) {
        super(screenModeService, deviceService, messageService, sessionProvier);
    }

    ngOnInit(): void {
        if (!this.item) {
            this.item = {} as Compra;
        }
    }

    formatoFecha(value: string) {
        let d = Date.parse(value);

        return formatDate(d, 'yyyy/MM/dd', 'es-MX');
    }

    onChangeTipoCompra(event){
        this.item.tipoCompra.id = event;
    }
    


}
