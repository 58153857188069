import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoOperacion } from 'src/app/api/compras/seguimiento-compra';

@Component({
  selector: 'axks-tipo-operacion-form',
  templateUrl: './tipo-operacion-form.component.html',
  styleUrls: ['./tipo-operacion-form.component.scss']
})
export class TipoOperacionFormComponent extends BaseView implements OnInit {
  
    @Input() item:TipoOperacion;
  
    constructor(screenModeService: OperationScreenModeService,
      deviceService: DeviceDetectorService,
      messageService: MessageService,
      sessionProvier: SessionClientProvider,
      ) {
      super(screenModeService,deviceService,messageService,sessionProvier);
    }
  
    ngOnInit(): void {
      if(!this.item){
        this.item = {};
      }
    }
  }
  