import { version } from '../../package.json';
export const environment = {
    version: version,
    production: false,
    dev: false,
    uat: true,

    appId: "compras-gui",
    empresas: [
        { id: 1, domain: 'uat.axkansoluciones.com' },
        { id: 2, domain: 'uat.sbsynergy.mx' },
        { id: 3, domain: 'uat.habitataxkan.com.mx' }
    ],

    sprofile: "https://sec.uat-ms.axkans.org/autentica/profile",

    msCompras: "https://compras.uat-ms.axkans.net/compras/compra",
    msEstatusCompra: "https://compras.uat-ms.axkans.net/compras/estatus-compra",
    msSeguimientoCompra: "https://compras.uat-ms.axkans.net/compras/seguimiento-compra",
    msPersona: 'https://rh.uat-ms.axkans.net/rh/empleado',
    msTipoCompra: 'https://compras.uat-ms.axkans.net/compras/tipo-compra',
    msTipoOperacion: 'https://compras.uat-ms.axkans.net/compras/tipo-operacion',
    msEmpleado: 'https://rh.uat-ms.axkans.net/rh/empleado/ps',

    theme: {
        default: 'https://static.uat-gui.axkans.org/portal/themes/blue-gray/styles/theme.css'
    },
    comprasProfile: 5
};
