import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TipoCompraPsService } from '../../../procs/tipo-compra/tipo-compra-ps.service';
import { MessageService } from 'primeng/api';
import { TipoCompra, TipoCompraReg } from 'src/app/api/tipo-compra/tipo-compra';

@Component({
  selector: 'axks-tipo-compra',
  templateUrl: './tipo-compra.component.html',
  styleUrls: ['./tipo-compra.component.scss']
})
export class TipoCompraComponent extends BaseMainComponent<TipoCompraPsService> implements OnInit {
  
  constructor(deviceService: DeviceDetectorService,
    messageService: MessageService,
    screenModeService: OperationScreenModeService, 
    sessionProvier: SessionClientProvider,
    private psService: TipoCompraPsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    return {
      item: {} as TipoCompra,
      minimumItem: {} as TipoCompra,
      registerItem: {} as TipoCompraReg
    } as ComponentItem;
  }

  getService(): TipoCompraPsService {
    return this.psService;
  }

  getColListDefinition(): ColumnDefinition[] {
    return [
      {header:'Clave', field:'id', inAll:true},
      {header:'Tipo compra', field:'tipoCompra', inDetail:true, inResultList:true},
      {header:'Descripción', field:'descripcion', inAll:true},
      
    ] as ColumnDefinition[];
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return "Tipo de compra";
  }
}