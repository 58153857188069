<ng-container *ngIf="seguimiento.idEstatus == ESTATUS.RECIBIDA || seguimiento.idEstatus == ESTATUS.SOLICITADA">
    <p>
        Selecciona alguna de las opciones siguientes:
    </p><br>
    <span class="p-float-label">
        <textarea [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize" [(ngModel)]="observaciones"
            [style]="{'width':'100%'}"></textarea>
        <label for="textarea">Observaciones*</label>
    </span>
    <br>
    <p-button label="Autorizar" icon="fa fa-check-square-o" [loading]="showProgress" (onClick)="onAccion($event, ACCION.AUTORIZAR)"></p-button> &nbsp;
    <p-button styleClass="p-button-danger" icon="fa fa-ban" label="Rechazar" [loading]="showProgress" (onClick)="onAccion($event, ACCION.RECHAZAR)"></p-button> &nbsp;
    <p-button label="Poner en espera" icon="fa fa-clock-o" [loading]="showProgress" (onClick)="onAccion($event, ACCION.ESPERAR)"></p-button> &nbsp;
</ng-container>

<div *ngIf="muestraFinalizacion(seguimiento.idEstatus)" class="cmp-form">
    <div class="p-grid frm-part ">
        <div class="p-sm-12 p-md-12 p-lg-12 p-col-12">
            <p>
                Selecciona la operación con la que se realizó la compra y agrega un comentario en el campo
                observaciones.
            </p>
        </div>
        <div class="p-sm-12 p-md-12 p-lg-12 p-col-12">
            <axks-tipo-operacion-cat [addNewEnable]="true" [operationMode]="operationMode"
                (onChange)="onChangeTipoOperacion($event)"></axks-tipo-operacion-cat>
        </div>
        <div class="p-sm-12 p-md-12 p-lg-12 p-col-12">
            <br>
            <span class="p-float-label">
                <textarea [rows]="4" [cols]="60" pInputTextarea autoResize="autoResize" [(ngModel)]="observaciones"
                    [style]="{'width':'100%'}"></textarea>
                <label for="textarea">Observaciones*</label>
            </span>
        </div>
    </div>

    <br>
    <p-button label="Finalizar compra" icon="fa fa-check-square" [loading]="showProgress" (onClick)="onAccion($event, ACCION.FINALIZAR)"></p-button> &nbsp;
    <p-button styleClass="p-button-danger" icon="fa fa-times-circle" label="Cancelar compra" [loading]="showProgress" (onClick)="onAccion($event, ACCION.CANCELAR)"></p-button> &nbsp;
</div>