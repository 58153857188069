<br>
<!-- {{newItem | json}} -->
<span class="p-float-label">
    <p-button icon="pi pi-plus" iconPos="left" *ngIf="addNewEnable && !showForm" (click)="onAddNewClick($event)"
        class="input-button">
    </p-button>
    <p-dropdown inputId="fl-caract" [(ngModel)]="value.id" [options]="catalog" optionLabel="tipoOperacion"
        optionValue="id" [autoDisplayFirst]="false" [virtualScroll]="virtualScroll" itemSize="10"
        (onChange)="selectOption($event,value)" autoWidth="false"
        [styleClass]="addNewEnable && !showForm ? 'with-button':''"></p-dropdown>
    <label for="fl-caract">Tipo de operación</label>
</span>


<p-dialog [(visible)]="showForm" [resizable]="true" [modal]="true" [style]="{width: '70vw', 'height':'auto'}"
    contentStyleClass="dialog-content-style" [draggable]="false">
    <ng-template pTemplate="header">
        Nuevo tipo de operación
    </ng-template>
    <axks-tipo-operacion-form [operationMode]="operationMode" [item]="newItem" [formFields]="[]"
        [class]="'cmp-form-'+1">
    </axks-tipo-operacion-form>

    <ng-template pTemplate="footer">
        <p-button label="Guardar" icon="pi pi-save" iconPos="left" (click)="onSaveClick($event)"></p-button>
        &nbsp;
        <p-button label="Cancelar" icon="pi pi-times" iconPos="left" (click)="onCancelClick($event)"></p-button>

    </ng-template>
</p-dialog>