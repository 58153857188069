import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess, Message } from '@axks/components';
import { Observable } from 'rxjs';
import { SeguimientoCompra } from 'src/app/api/compras/seguimiento-compra';
import { AccionSeguimientoEnum } from 'src/app/api/enums/solicitud.enum';
import { SeguimientoCompraRestcService } from 'src/app/client/compra/seguimiento-compra-restc.service';

@Injectable({
    providedIn: 'root'
})
export class SeguimientoCompraPsService extends BaseProcess<SeguimientoCompraRestcService>{

    client: SeguimientoCompraRestcService;
    constructor(httpClient: HttpClient, restc: SeguimientoCompraRestcService) {
        super(httpClient, restc);
        this.client = restc;
    }

    registraSeguimiento(item: SeguimientoCompra, accion: AccionSeguimientoEnum): Observable<Message>  {
        if(!accion){
            throw new Error("Es necesario proporcionar una acción a realizar.")
        }

        return this.client.saveSeguimiento(item, accion);
    }

}
