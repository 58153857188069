<div class="progress container" *ngIf="!myLoadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        [style]="{width: '35px', height: '35px'}" strokeWidth="8"></p-progressSpinner>
</div>
<!-- {{items | json}} -->
<ng-container *ngIf="myLoadContent">
    <p-listbox *ngIf="showContent" [options]="items" [style]="{'width':'280px','border':'unset'}"
        (onClick)="selectItem($event)">
        <ng-template pTemplate="body" let-item>
            <div>
                <i class="pi pi-check-square"></i>
            </div>
            <div style="padding-left: 0.6rem; font-size: 0.8rem;">
                * {{item.compra}} <br> * {{item.nombreEmpleado}} <br> * {{item.fecSolicitud | date: "dd-MM-yyyy"}}
            </div>
        </ng-template>
    </p-listbox>

    <div *ngIf="!showContent" [style]="{'width':'280px','border':'unset', 'max-height':'200px'}">
        <span style="font-size: .8rem;">No hay solicitudes de compra pendientes que atender.</span>
    </div>
</ng-container>