<div class="p-grid frm-part" *ngIf="item">

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('compra')">
        <span class="p-float-label">
            <span class="p-float-label">
                <input id="fl-cont" type="text" pInputText [(ngModel)]="item.compra">
                <label for="fl-coment"><span *ngIf="operationMode == modoOperacion.ADDNEW"
                        [style]="{'color':'red'}">*</span> Compra</label>
            </span>

        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('monto')">
        <span class="p-float-label">
            <p-inputNumber [(ngModel)]="item.monto" inputId="currency-us" mode="currency" currency="MXN" locale="es-MX"
                [style]="{'width':'100%'}">
            </p-inputNumber>
            <label for="fl-coment"><span *ngIf="operationMode == modoOperacion.ADDNEW"
                    [style]="{'color':'red'}">*</span> Monto</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('empleado') && modoGestion">
        <span class="p-float-label">
            <p-dropdown inputId="fl-emp" [(ngModel)]="item.idEmpSolicitud" [options]="empleados" optionLabel="nombreCompleto"
                optionValue="id" [autoDisplayFirst]="false" itemSize="10" autoWidth="false"
                [filter]="true" filterBy="nombreCompleto"></p-dropdown>
            <label for="fl-emp">Empleado solicitante</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('tipoCompra')">
        <axks-tipo-compra-cat [addNewEnable]="true" [(value)]="item.tipoCompra" [operationMode]="operationMode"
            (onChange)="onChangeTipoCompra($event)"></axks-tipo-compra-cat>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('estatusCompra')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-est" [(ngModel)]="item.idEstatusCompra" [options]="estatus" optionLabel="estatus"
                optionValue="id" [autoDisplayFirst]="false" itemSize="10" autoWidth="false"></p-dropdown>
            <label for="fl-est">Estatus de compra</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('descripcion')">
        <span class="p-float-label">
            <input id="fl-nmemp" type="text" pInputText [(ngModel)]="item.descripcion">
            <label for="fl-nmemp">Descripción</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="operationMode == modoOperacion.ADDNEW">
        <label for="fl-coment" style="font-size: 0.7rem; color: red"><span *ngIf="operationMode == modoOperacion.ADDNEW"
                [style]="{'color':'red'}">*</span> Campos requeridos</label>
    </div>

</div>