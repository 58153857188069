import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoCompra } from '../../../api/tipo-compra/tipo-compra';

@Component({
  selector: 'axks-tipo-compra-form',
  templateUrl: './tipo-compra-form.component.html',
  styleUrls: ['./tipo-compra-form.component.scss']
})
export class TipoCompraFormComponent extends BaseView implements OnInit {
  
  @Input() item:TipoCompra;

  constructor(screenModeService: OperationScreenModeService,
    deviceService: DeviceDetectorService,
    messageService: MessageService,
    sessionProvier: SessionClientProvider,
    ) {
    super(screenModeService,deviceService,messageService,sessionProvier);
  }

  ngOnInit(): void {
    if(!this.item){
      this.item = {};
    }
  }
}
