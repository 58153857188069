import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageCodes, OperationMode } from '@axks/components';
import { MessageService } from 'primeng/api';
import { TipoOperacion } from 'src/app/api/compras/seguimiento-compra';
import { TipoOperacionPsService } from 'src/app/procs/tipo-operacion/tipo-operacion-ps.service';

@Component({
    selector: 'axks-tipo-operacion-cat',
    templateUrl: './tipo-operacion-cat.component.html',
    styleUrls: ['./tipo-operacion-cat.component.scss']
})
export class TipoOperacionCatComponent implements OnInit, OnChanges {

    catalog: TipoOperacion[];
    virtualScroll: boolean = false;

    showForm: boolean = false;
    newItem: TipoOperacion;


    @Input() value: TipoOperacion;
    /**
     * Habilita la funcionalidad para agregar
     */
    @Input() addNewEnable: boolean = false;

    /** 
     * Indica si hay que tomar en cuenta el modo de operación 
     * de la pantalla.
     */
    @Input() operationMode: OperationMode;


    @Output() onChange = new EventEmitter<TipoOperacion>();

    constructor(private tipoOperacionPs: TipoOperacionPsService,
        private messages: MessageService,
        private route: ActivatedRoute) { }

    ngOnInit(): void {
        if (!this.value) {
            this.value = {} as TipoOperacion;
        }
        if (!this.addNewEnable) {
            this.addNewEnable = false;
        }

        if (this.operationMode) {
            this.enableAddNewButton();
        }
        this.populate();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.populate();
        if (this.operationMode) {
            this.enableAddNewButton();
        }
    }

    enableAddNewButton() {
        switch (this.operationMode) {
            case OperationMode.ADDNEW:
            case OperationMode.EDIT:
                this.addNewEnable = true;
                break;
            default:
                this.addNewEnable = false;
                break;
        }
    }

    populate() {
        this.virtualScroll = false;
        this.tipoOperacionPs.all().subscribe({
            next: (data) => {
                this.catalog = data;
                if (this.catalog.length > 10) {
                    this.virtualScroll = true;
                }
            },
            error: (error) => {
                try {

                    let message = error.error as Message;
                    this.processMessage(message);
                } catch (e) {
                    console.debug("Error")
                    console.debug(error)
                }
            }
        })
    }

    processMessage(message: Message) {
        let messText: string;
        let sevMes = "info";
        switch (message.code) {
            case MessageCodes.NOT_FOUND_RESULTS.valueOf():
                messText = "No se localizaron los tipos de operación. Deberá registrarlos primero.";
                break;
            default:
                messText = "Error desconocido.";
                sevMes = "warn"
                console.debug(message);
                break;
        }

        this.messages.add({ severity: sevMes, summary: "Catálogo de tipos de operación", detail: messText });
    }

    selectOption(event, value) {
        this.onChange.emit(value);
    }

    onAddNewClick(event) {
        this.operationMode = OperationMode.ADDNEW;
        this.showForm = true;
        this.newItem = {} as TipoOperacion;
    }

    onSaveClick(event) {
        this.tipoOperacionPs.register(this.newItem).subscribe(
            (data) => {
                this.populate();
                this.showForm = false;
                this.newItem = {};
            }, (error) => {
                try {
                    let message = error.error as Message;
                    this.processMessage(message);
                } catch (e) {
                    console.debug("Error")
                    console.debug(error)
                }
                this.showForm = false;
                this.newItem = {};
            });
    }

    onCancelClick(event) {
        this.showForm = false;
        this.newItem = {};
    }
}

