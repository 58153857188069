<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('estatus')">
        <br>
        <span class="p-float-label">
            <input id="fl-estatus" type="text" pInputText [(ngModel)]="item.estatusCompra">
            <label for="fl-estatus">Estatus</label>
        </span>
    </div>
    
</div>

