<!-- <p>tipo-compra-form works!</p> -->
<!-- {{item | json}} -->
<div class="p-grid frm-part" >
    
    <div class="p-col-4">
        <span class="p-float-label">
            <input id="float-input" type="text" pInputText [(ngModel)]="item.tipoCompra">
            <label for="float-input">Tipo Compra</label>
        </span>
    </div>


    <div class="p-col-4">
        <span class="p-float-label">
            <input id="descripcion" type="text" pInputText [(ngModel)]="item.descripcion">
            <label for="descripcion">Descripción</label>
        </span>
    </div>
    
</div>