import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Estatus } from 'src/app/api/compras/estatus-compra';
import { SeguimientoCompra } from 'src/app/api/compras/seguimiento-compra';
import { AccionSeguimientoEnum, EstatusSolicitud } from 'src/app/api/enums/solicitud.enum';
import { CompraRestcService } from 'src/app/client/compra/compra-restc.service';
import { CompraPsService } from 'src/app/procs/compra/compra-ps.service';
import { EstatusCompraPsService } from 'src/app/procs/compra/estatus-compra-ps.service';
import { SeguimientoCompraPsService } from 'src/app/procs/compra/seguimiento-compra-ps.service';
import { Empleado } from '../../api/empleado';

@Component({
    selector: 'axks-det-historia',
    templateUrl: './det-historia.component.html',
    styleUrls: ['./det-historia.component.scss']
})
export class DetHistoriaComponent extends BaseView implements OnInit, OnChanges {
    ESTATUS_SOLICITUD = EstatusSolicitud;

    @Input() historico: SeguimientoCompra[];
    @Input() modoGestion: boolean;

    displayDialog: boolean = false;

    selSeguimiento: SeguimientoCompra;

    constructor(screenModeService: OperationScreenModeService,
        deviceService: DeviceDetectorService,
        messageService: MessageService,
        sessionProvier: SessionClientProvider,
        private estatusPs: EstatusCompraPsService,
        private segCompPs: SeguimientoCompraPsService,
        private compraPs: CompraPsService
    ) {
        super(screenModeService, deviceService, messageService, sessionProvier);
    }


    ngOnInit(): void {

        if (!this.historico) {
            this.historico = [];
        }

        this.historico = this.historico.sort((a, b) => {
            let dateA = Date.parse(a.fecEstatus);
            let dateB = Date.parse(b.fecEstatus);
            return +dateA <= +dateB ? 1 : 0;
        });


    }

    ngOnChanges(changes: SimpleChanges): void {
        /* let current = changes.historico.currentValue;
        let prev = changes.historico.previousValue;
        try {
            if (current.length != prev.length) {
                this.historico.sort((a, b) => {
                    return a.fecEstatus > b.fecEstatus ? 0 : 1;
                })
            }
        } catch (e) { } */

    }

    onSelSeguimiento(event, seguimiento: SeguimientoCompra) {
        let profile = this.getProfile() as ProfileC;
        this.selSeguimiento = seguimiento;
        this.displayDialog = true;
        if (seguimiento.idEstatus == EstatusSolicitud.SOLICITADA
            && this.modoGestion && seguimiento.actual) {
            let segCompra = {
                idCompra: this.selSeguimiento.idCompra,
                idEmpleado: profile.idEmpleado,
            } as SeguimientoCompra;

            this.segCompPs.registraSeguimiento(segCompra, AccionSeguimientoEnum.RECIBIR).subscribe({
                next: (data) => {
                    this.infoMessage("Seguimiento de compra", "La solicitud se ha recibido.");
                    this.reloadHistorico()
                }
            });
        }
    }

    dialogStyle(): any {
        if (this.isMobile()) {
            return { width: '100%' };
        }

        return { width: '50%' };
    }

    onFinAccion(event) {
        this.displayDialog = false;
        //this.selSeguimiento = undefined;
        this.reloadHistorico();
    }

    getEstatusLabel(idEstatus: number): string {
        return this.estatusPs.getEstatusLabel(idEstatus);
    }


    validaAccion(idEstatus: number) {

        let estatus = this.estatusPs.getEstatusById(idEstatus);

        if (!estatus) return false;

        switch (idEstatus) {
            case EstatusSolicitud.CANCELADA:
            case EstatusSolicitud.RECHAZADA:
            case EstatusSolicitud.FINALIZADA:
                return false;
        }


        if (estatus.operaCompras && this.modoGestion) {
            return true;
        }

        if (!this.modoGestion) {
            switch (idEstatus) {
                /* case EstatusSolicitud.SOLICITADA:
                case EstatusSolicitud.RECIBIDA:
                case EstatusSolicitud.AUTORIZADA:
                case EstatusSolicitud.EN_ESPERA: */
                case EstatusSolicitud.CANCELADA:
                    return true;
            }
            return false;
        }

        return false;
    }

    reloadHistorico() {
        this.compraPs.findById(this.selSeguimiento.idCompra).subscribe({
            next: (dcompra) => {
                this.historico = dcompra['historico'];
            }
        });
    }

}
