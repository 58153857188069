import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message } from '@axks/components';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable, Subject, pipe } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SeguimientoCompra } from '../../api/compras/seguimiento-compra';
import { tap } from 'rxjs/operators';
import { AccionSeguimientoEnum } from 'src/app/api/enums/solicitud.enum';

@Injectable({
    providedIn: 'root'
})
export class SeguimientoCompraRestcService extends ARestClient {


    private _refresh$ = new Subject<void>();
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    get refresh$() {
        return this._refresh$;
    }

    getBaseEndpoint(): string {
        return environment.msSeguimientoCompra + "/";
    }
    processBody(res: any) {
        return res || {};
    }

    getRestPaths(): RestBasicPaths {
        let paths = super.getRestPaths();
        paths.remove = "";
        paths.detail = "";
        paths["seguimiento"] = "";

        return paths;
    }

    saveSeguimiento(item: SeguimientoCompra, accion: AccionSeguimientoEnum): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["seguimiento"];

        if (!path.endsWith("/")) {
            path += "/"
        }

        path += accion;
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");
        
        return this.httpClient.post(path, item,{headers});

    }

    /* solIngresadaAct(item: SeguimientoCompra): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solIngresadaAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()

            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers })
            .pipe(
                tap(() => {
                    this._refresh$.next();
                })
            ) as Observable<Message>;

    }

    solRecibidaAct(item: SeguimientoCompra): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solRecibidaAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers })
            .pipe(
                tap(() => {
                    this._refresh$.next();
                })
            ) as Observable<Message>;
    }

    solAceptadaAct(item: SeguimientoCompra): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solAceptadaAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers })
            .pipe(
                tap(() => {
                    this._refresh$.next();
                })
            ) as Observable<Message>;
    }

    solEnEsperaAct(item: SeguimientoCompra): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solEnEsperaAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers })
            .pipe(
                tap(() => {
                    this._refresh$.next();
                })
            ) as Observable<Message>;
    }

    solRechazadaAct(item: SeguimientoCompra): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solRechazadaAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers })
            .pipe(
                tap(() => {
                    this._refresh$.next();
                })
            ) as Observable<Message>;
    } */

}
