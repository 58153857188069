import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { CompraPsService } from 'src/app/procs/compra/compra-ps.service';
import { Compra } from 'src/app/api/compras/compra'
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { SeguimientoService } from 'src/app/procs/solicitud/observable/seguimiento-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Estatus } from 'src/app/api/compras/estatus-compra';
import { EstatusCompraPsService } from 'src/app/procs/compra/estatus-compra-ps.service';
import { EmpleadoPsService } from '../../../procs/empleado/empleado-ps.service';
import { EmpleadoNombre } from 'src/app/api/empleado/empleado';

@Component({
    selector: 'axks-compras',
    templateUrl: './compras.component.html',
    styleUrls: ['./compras.component.scss']
})
export class ComprasComponent extends BaseMainComponent<CompraPsService> implements OnInit, AfterViewInit {

    modoGestion: boolean = false;
    estatus: Estatus[];
    empleados: EmpleadoNombre[];
    urlParams = new URLSearchParams(window.location.search);
    redirect: boolean = false;
    mostrarDetalle: boolean = false;

    constructor(
        protected deviceService: DeviceDetectorService,
        protected messageService: MessageService,
        protected screenModeService: OperationScreenModeService,
        protected sessionProvier: SessionClientProvider,
        private psService: CompraPsService,
        segObs: SeguimientoService,
        private activatedRoute: ActivatedRoute,
        private estatusRestc: EstatusCompraPsService,
        private empRestc: EmpleadoPsService,
        private router: Router
    ) {
        super(deviceService, messageService, screenModeService, sessionProvier);

        segObs.recargaHistoria.subscribe(
            (data) => {
                if (data == true) {
                    this.rowSelect(this.item.minimumItem);
                }
            }
        );

        // Se obtiene el parametro 'r' para indicar que se carga la pagina de un redireccionamiento del dashboard
        if (this.urlParams.get('r')) {
            this.redirect = true;
        }
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initMode();
        this.getDetalleSize();
        this.initCatEstatus();
        setTimeout(() => {
            if (this.modoGestion == true) {
                this.initCatEmpleadosActivos();
            }
        }, 1000);

        if (this.redirect) {
            setTimeout(() => {
                this.initDetalle();
            }, 1);
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        setTimeout(() => {
            if (!this.item.item) {
                this.item.item = {
                    tipoCompra: {}
                } as Compra;
            }
            if (!this.modoGestion) {
                this.item.item.idEmpSolicitud = this.getProfile().idEmpleado;
            }

            this.find(null);
        }, 500);

    }

    initDetalle() {
        this.screenModeService.screenMode.next(OperationMode.SELECTED);
        let itemMin = {
            id: parseInt(this.urlParams.get('sid'))
        } as Compra;
        this.rowSelect(itemMin);
        this.mostrarDetalle = true;
    }

    initEmptyItem(): ComponentItem {
        let profile = this.getProfile() as ProfileC;
        this.initMode();

        return {
            item: {
                idEmpresa: profile.empresa,
                idEmpSolicitud: (!this.modoGestion ? profile.idEmpleado : null),
                tipoCompra: {}
            } as Compra,
            minimumItem: {
                idEmpresa: profile.empresa,
                idEmpSolicitud: (!this.modoGestion ? profile.idEmpleado : null),
                nombreEmpleado: profile.nombreEmpleado,
                tipoCompra: {},
                actual: true
            } as Compra,
            registerItem: {
                idEmpresa: profile.empresa,
                idEmpSolicitud: (!this.modoGestion ? profile.idEmpleado : null),
                nombreEmpleado: (!this.modoGestion ? profile.nombreEmpleado : null),
                tipoCompra: {},
                actual: true
            } as Compra,

        } as ComponentItem;
    }

    getService(): CompraPsService {
        return this.psService;
    }
    getColListDefinition(): ColumnDefinition[] {
        return [

            { header: 'No. Solicitud', field: 'id', inDetail: true, inResultList: true },
            { header: 'Compra', field: 'compra', inAll: true },
            { header: 'Descripción', field: 'descripcion', inDetail: true },
            { header: 'Monto', field: 'monto', inAll: true, formatFunction: this.formatMonto },
            { header: 'Tipo de compra', field: 'tipoCompra.tipoCompra', inAll: true },
            { header: 'Empleado', field: 'nombreEmpleado', inAll: this.modoGestion },
            { header: 'Estatus', field: 'estatusCompra', inAll: true },
            { header: 'Fecha de solicitud', field: 'fecSolicitud', inAll: true, formatFunction: this.formatDate },
            { header: 'Fecha fin de Solicitud', field: 'fecFinaliza', inAll: true, formatFunction: this.formatDate }

        ] as ColumnDefinition[];
    }
    getIdOfSelectedItem(): number {
        return this.item.minimumItem.id || this.item.item.id;
    }
    getComponentTitle(): string {
        return "Compras";
    }

    formatDate(fecha: string): string {
        let pipe = new DatePipe('es-MX');
        return pipe.transform(fecha, "dd 'de' MMMM 'de' yyyy");
    }

    formatMonto(monto): string {
        let pipe = new CurrencyPipe("es-MX", "MXN");
        return pipe.transform(monto);
    }

    componentsFields() {
        super.componentsFields();
        this.formSearch = [
            "compra", "fecSolicitud", "Empleado", "idEmpleado", "tipoCompra", "estatusCompra", "empleado"
        ];
        this.formFields = [
            "compra", "fecSolicitud", "Empleado", "idEmpleado", "descripcion", "monto", "tipoCompra"
        ];
        this.detailFields = [];


    }
    getDetalleSize(): any {
        if (!this.isMobile()) {
            return {};
        }
    }

    initMode() {

        let routeData = this.activatedRoute.snapshot.data;
        let comprasProfile = environment.comprasProfile;

        if (!this.hasRol(comprasProfile)) {
            this.modoGestion = false;
            return;
        }

        this.modoGestion = !(!routeData || routeData.compras == false);
    }

    hasRol(rolId: number) {
        let profile = this.getProfile() as ProfileC;
        try {

            if (profile.rol.find(r => r.idRol === rolId)) {
                return true;
            }

        } catch (e) { }

        return false;
    }

    initCatEstatus() {
        this.estatusRestc.all().subscribe(
            (data) => {
                this.estatus = data;
            },
            (error) => {
                this.errorMessage("Error", "No se pudo obtener el catalogo de estus de compra");
            }
        );
    }

    initCatEmpleadosActivos() {
        this.empRestc.empleadosActivos(this.profile.empresa).subscribe(
            (data) => {
                this.empleados = data;
            },
            (error) => {
                this.errorMessage("Error", "No se pudo obtener el catalogo de empleados activos");
            }
        );
    }

    cancel(event: any): void {
        this.mostrarDetalle = false;
        super.cancel(event);
        this.urlParams.delete('r');
        this.urlParams.delete('sid');
        window.history.replaceState(null, document.title, window.location.origin + window.location.pathname + '?' + this.urlParams.toString())
    }

}
