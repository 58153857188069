<ng-container *ngIf="seguimiento.idEstatus != ESTATUS.FINALIZADA">
    <p>
        Para cancelar la solicitud de compra proporciona una observación y selecciona cancelar:
    </p><br>
    <span class="p-float-label">
        <textarea [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize" [(ngModel)]="observaciones"
        [style]="{'width':'100%'}"></textarea>
        <label for="textarea">Observaciones*</label>
    </span>
    <br>
    <p-button styleClass="p-button-danger" icon="fa fa-times-circle" label="Cancelar compra" [loading]="showProgress" (onClick)="onAccion($event, ACCION.CANCELAR)"></p-button> &nbsp;
</ng-container> 