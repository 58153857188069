import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, Message, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { AccionSeguimientoEnum, EstatusSolicitud } from 'src/app/api/enums/solicitud.enum';
import { SeguimientoCompra } from 'src/app/api/compras/seguimiento-compra';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { SeguimientoCompraPsService } from 'src/app/procs/compra/seguimiento-compra-ps.service';
import { ProfileC } from '@axks/components/lib/api/views/profile';

@Component({
    selector: 'axks-accion-seguimiento-empleado',
    templateUrl: './accion-seguimiento-empleado.component.html',
    styleUrls: ['./accion-seguimiento-empleado.component.scss']
})
export class AccionSeguimientoEmpleadoComponent extends BaseView implements OnInit {
    ACCION = AccionSeguimientoEnum;
    ESTATUS = EstatusSolicitud;
    @Input() seguimiento: SeguimientoCompra;
    observaciones: string;
    @Output() onFinAccion = new EventEmitter();

    constructor(
        protected screenModeService: OperationScreenModeService,
        protected deviceService: DeviceDetectorService,
        protected messageService: MessageService,
        protected sessionProvier: SessionClientProvider,
        private segCompPs: SeguimientoCompraPsService
    ) {
        super(screenModeService, deviceService, messageService, sessionProvier);
    }

    ngOnInit(): void {
        if (!this.seguimiento) {
            this.seguimiento = {};
        }
    }

    onAccion(event, accion: AccionSeguimientoEnum) {

        if (!this.observaciones) {
            this.warningMessage("Seguimiento a la compra", "Favor de proporcionar un comentario en el campo observaciones.");
        } else {
            this.showProgress = true;
            let profile = this.getProfile() as ProfileC;
            let seg = {
                idCompra: this.seguimiento.idCompra,
                observaciones: this.observaciones,
                idEmpleado: profile.idEmpleado
            } as SeguimientoCompra;

            this.segCompPs.registraSeguimiento(seg, accion).subscribe(
                (data) => {
                    this.infoMessage("Seguimiento a la compra", "Se almacenó correctamente la acción.");
                    this.onFinAccion.emit();
                    this.showProgress = false;
                },
                (error) => {
                    this.showProgress = false;
                    this.errorMessage("Error", "No se pudo avanzar la solicitud");
                }
            );
        }
    }

}
