import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Estatus } from 'src/app/api/compras/estatus-compra';
import { EstatusCompraRestcService } from 'src/app/client/compra/estatus-compra-restc.service';

@Injectable({
    providedIn: 'root'
})
export class EstatusCompraPsService extends BaseProcess<EstatusCompraRestcService>{

    catEstatus: Estatus[];

    constructor(httpClient: HttpClient,
        crest: EstatusCompraRestcService) {
        super(httpClient, crest);
        this.catalog();
    }

    catalog(){
        this.all().subscribe({
            next: (data) => {
                this.catEstatus = data;
            }
        })
    }

    getEstatusById(idEstatus: number): Estatus {
        try {
            return this.catEstatus.find(est=> est.id === idEstatus);
        } catch (error) {
            
        }
        return undefined;
    }

    getEstatusLabel(idEstatus: number): string {
        try {
            let estatus = this.getEstatusById(idEstatus);
            
            return estatus.estatus;
        } catch (error) {}
        return "";
    }
}
