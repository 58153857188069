import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { EstatusSolicitud } from 'src/app/api/enums/solicitud.enum';
import { SeguimientoCompra } from 'src/app/api/compras/seguimiento-compra';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { SeguimientoCompraPsService } from 'src/app/procs/compra/seguimiento-compra-ps.service';
import { SeguimientoService } from 'src/app/procs/solicitud/observable/seguimiento-service.service';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { CompraRestcService } from 'src/app/client/compra/compra-restc.service';
import { Empleado } from 'src/app/api/empleado';
import { EstatusCompraPsService } from 'src/app/procs/compra/estatus-compra-ps.service';

@Component({
    selector: 'axks-det-seguimiento',
    templateUrl: './det-seguimiento.component.html',
    styleUrls: ['./det-seguimiento.component.scss']
})
export class DetSeguimientoComponent extends BaseView implements OnInit {

    ESTATUS = EstatusSolicitud;
    @Input() seguimiento: SeguimientoCompra;
    @Input() modoGestion: boolean;

    habilitaAccion: boolean;

    constructor(screenModeService: OperationScreenModeService,
        deviceService: DeviceDetectorService,
        messageService: MessageService,
        sessionProvier: SessionClientProvider,
        private solPs: SeguimientoCompraPsService,
        private segObs: SeguimientoService,
        private estatusPs: EstatusCompraPsService) {
        super(screenModeService, deviceService, messageService, sessionProvier);
    }

    ngOnInit(): void {
        this.initCatalogos()
        if (!this.seguimiento) {
            this.seguimiento = {} as SeguimientoCompra;
        }
        this.notificaRecepcion();
    }

    initCatalogos() {
        let profile = this.getProfile() as ProfileC;
    }


    notificaRecepcion() {
        let profile = this.getProfile() as ProfileC;

        if (profile.idEmpleado == 13 && this.seguimiento.actual == true) {
            let seg = {} as SeguimientoCompra;
            seg.idCompra = this.seguimiento.idCompra;
            seg.idEstatus = this.seguimiento.idEstatus;
            seg.idEmpleado = this.seguimiento.idEmpleado;
            seg.actual = true;

            console.log("objeto construido para iniciar")
            console.log(seg)

            /* this.solPs.solIngresadaAct(seg).subscribe(
                (data) => {
                    this.segObs.recargaHistoria.next(true);
                }
            ); */
        }

    }

    

}
