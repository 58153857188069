import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EstatusCompraPsService } from '../../../procs/compra/estatus-compra-ps.service';
import { Estatus } from '../../../api/compras/estatus-compra';
import { ProfileC } from '@axks/components/lib/api/views/profile';

@Component({
  selector: 'axks-estatus-compras',
  templateUrl: './estatus-compras.component.html',
  styleUrls: ['./estatus-compras.component.scss']
})
export class EstatusComprasComponent extends BaseMainComponent<EstatusCompraPsService> implements OnInit {
  

  constructor(protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected screenModeService: OperationScreenModeService, 
    protected sessionProvier: SessionClientProvider,
    private psService: EstatusCompraPsService) {
      super(deviceService, messageService, screenModeService, sessionProvier);
     }

  ngOnInit(): void {
    super.ngOnInit();
    let profile = this.getProfile() as ProfileC;
    console.log("perfil de usuario")
    console.log(profile)
  }

  initEmptyItem(): ComponentItem {
    return {
      item: {} as Estatus,
      minimumItem: {} as Estatus,
      registerItem: {} as Estatus
    } as ComponentItem;
  }

  getService(): EstatusCompraPsService {
    return this.psService;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      {header:'Clave', field:'id', inDetail:true, inResultList:true},
      {header:'Estatus de compra', field:'estatusCompra', inAll:true},
      

    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem(): number {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return "Estatus de compras";
  }
}
