<div class="progress container" *ngIf="!loadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner" strokeWidth="8">
    </p-progressSpinner>
</div>

<ng-container *ngIf="loadContent">
    <!-- {{getProfile().idEmpleado}} - {{ getProfile().nombreEmpleado}} <br> -->
    <!-- {{item | json}} -->
    <!-- {{modoGestion}} <br> -->
    <!-- {{profile | json}} -->
    <axks-operations-bar (addNew)="addNew($event)" addNewLabel="Registrar" [addNewButton]="!modoGestion" searchLabel="Buscar"
        [searchButton]="!modoGestion" [sectionTitle]="getComponentTitle()" [refreshButton]="false">
    </axks-operations-bar>

    <div class="p-grid" *ngIf="showDetail() || mostrarDetalle" [style]="getDetalleSize()">
        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12 detail-sect">
            <axks-detail-tab class="axks-detail-tab-100" [data]="workItem()" [colsDefinition]="colDefinition"
                [detailFields]="detailFields">
            </axks-detail-tab>
        </div>
        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12 detail-sect">
            <axks-det-historia *ngIf="item.item.historico" [modoGestion]="modoGestion"
                [historico]="item.item.historico"></axks-det-historia>
        </div>
    </div>

    <axks-compras-form #cmpForm *ngIf="showForm() && !mostrarDetalle" [item]="workItem()" [formSearch]="formSearch"
        [formFields]="formFields" [operationMode]="operationMode" [class]="'cmp-form-' + operationMode"
        [estatus]="estatus" [modoGestion]="modoGestion" [empleados]="empleados">
    </axks-compras-form>

    <axks-actions-bar (save)="save($event)" (cancel)="cancel($event)" (clean)="clean($event)" (find)="find($event)"
        (print)="print($event)" [printButton]="false" [editButton]="false" [deleteButton]="false" (edit)="edit($event)"
        (delete)="delete($event)" (csvFile)="csvFile($event)" (pdfFile)="pdfFile($event)" (txtFile)="txtFile($event)"
        saveLabel="Guardar" cancelLabel="Cancelar" cleanLabel="Limpiar">
    </axks-actions-bar>

    <axks-result-list [data]="resultList" [colsDefinition]="colDefinition" (rowSelect)="rowSelect($event)"
        (rowUnselect)="rowUnselect($event)" [listFields]="listFields" *ngIf="showResultList()">
    </axks-result-list>
</ng-container>

<p-toast></p-toast>